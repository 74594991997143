<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12" v-if="resource">
          <ion-list>
            <ion-list-header> Invoice #: {{ resource.id }} </ion-list-header>
            <ion-item v-if="resource.payment">
              <ion-label>
                payment {{ resource.payment.status }} on
                {{ new Date(resource.payment.updated_at).toDateString() }}
              </ion-label>
            </ion-item>
            <ion-item>
              <!-- <ion-buttons> -->
              <ion-button
                :href="`${base_url}api/v1/fee-invoice-print/${resource.id}?pat=${token}`"
                :download="`fee_invoice_${resource.id}`"
                color="success"
              >
                Invoice
                <ion-icon
                  :ios="documentOutline"
                  :md="documentSharp"
                  size="small"
                  slot="icon-only"
                ></ion-icon>
              </ion-button>

              <ion-button
                :href="`${base_url}api/v1/fee-invoice-receipt/${resource.id}?pat=${token}`"
                :download="`fee_invoice_receipt_${resource.id}`"
                :disabled="receiptStatus()"
              >
                Receipt
                <ion-icon
                  :ios="documentOutline"
                  :md="documentSharp"
                  size="small"
                  slot="icon-only"
                ></ion-icon>
              </ion-button>
              <!-- </ion-buttons> -->
            </ion-item>
            <ion-item lines="none">
              <ion-label>
                Created:
                {{ new Date(resource.bill_fee.bill.created_at).toDateString() }}
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-label>
                Due:
                {{
                  new Date(resource.bill_fee.bill.bill_due_date).toDateString()
                }}
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-label>
                From:
                {{
                  new Date(resource.bill_fee.bill.bill_from_date).toDateString()
                }}
                to
                {{
                  new Date(resource.bill_fee.bill.bill_to_date).toDateString()
                }}
              </ion-label>
            </ion-item>
          </ion-list>

          <ion-list>
            <ion-list-header>
              {{ resource.user.user_detail.name }}
            </ion-list-header>
            <ion-item lines="none">
              <ion-label>
                {{ resource.user.email }}
              </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-label>
                Class {{ resource.standard.name }} for Session:
                {{ resource.bill_fee.bill.session.name }}
              </ion-label>
            </ion-item>
          </ion-list>

          <ion-list v-for="item in resource.fee_invoice_items" :key="item.id">
            <ion-list-header>
              {{ item.name }}
            </ion-list-header>
            <ion-item>
              <ion-label>
                Net Charge: Rs {{ item.amount_in_cent / 100 }}/-
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                Tax: Rs
                {{ (item.gross_amount_in_cent - item.amount_in_cent) / 100 }}/-
              </ion-label>
            </ion-item>
          </ion-list>

          <ion-list>
            <ion-item lines="none">
              <ion-label>
                Total: Rs {{ resource.gross_amount_in_cent / 100 }}/-
              </ion-label>
            </ion-item>
          </ion-list>

          <ion-button
            type="button"
            expand="full"
            class="ion-margin-vertical"
            @click="showRazorpay"
            :disabled="payButtonStatus()"
          >
            {{ payButton }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import base_url from "../../../apis/base_url";
import "capacitor-razorpay";
import { Plugins } from "@capacitor/core";
const { Checkout } = Plugins;

import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  alertController,
} from "@ionic/vue";

import { documentOutline, documentSharp } from "ionicons/icons";

export default {
  props: ["invoice"],

  components: {
    BaseLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonButton,
    IonIcon,
  },

  data() {
    return {
      title: "Invoice",
      backLink: "invoice",
      reloadable: false,

      documentOutline,
      documentSharp,
      base_url,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    resource() {
      return this.$store.getters["invoice/showResource"];
    },

    token() {
      return this.$store.getters["auth/token"];
    },

    razorpay() {
      return this.$store.getters["razorpay/showResource"];
    },

    payButton() {
      const payment = this.resource.payment;

      let text = "Pay";

      if (payment) {
        switch (payment.status) {
          case "created":
            text = "Pay";
            break;
          case "captured":
            text = "Paid";
            break;
          default:
            text = "Payment processing";
        }
      }

      return text;
    },
  },

  methods: {
    alerter(header, message) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: ["Close"],
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        console.log(error.request);
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    payButtonStatus() {
      const payment = this.resource.payment;

      let disabled_status = true;

      if (!payment) {
        disabled_status = false;
      }

      if (payment) {
        const failed =
          payment.status === "failed" || payment.status === "created"
            ? true
            : false;
        disabled_status = !failed;
      }

      return disabled_status;
    },

    receiptStatus() {
      const payment = this.resource.payment;
      let status = true;

      if (payment) {
        const captured = payment.status === "captured" ? true : false;
        status = !captured;
      }

      return status;
    },

    async showRazorpay() {
      await this.fetchRazorpay();

      const options = {
        order_id: this.razorpay.razorpayData.order_id,
        currency: this.razorpay.razorpayData.currency,
        amount: this.razorpay.razorpayData.amount.toString(),

        key: this.razorpay.razorpayData.key,
        name: this.razorpay.razorpayData.name,
        description: this.razorpay.razorpayData.description,
        image: this.razorpay.razorpayData.image,

        prefill: {
          name: this.razorpay.user.user_detail.name,
          email: this.razorpay.user.email,
          contact: this.razorpay.user.user_detail.phone,
        },
      };

      try {
        let data = await Checkout.open(options);
        this.verifyPayment(data.response);
      } catch (error) {
        console.log(error["description"]);
      }
    },

    async verifyPayment(data) {
      try {
        await this.$store.dispatch("razorpay/verifyPayment", {
          payment_id: data.razorpay_payment_id,
          signature: data.razorpay_signature,
          invoice_id: this.invoice,
        });

        this.fetchResource();

        this.$router.replace({
          name: "payment.successfull",
          invoice: this.invoice,
        });
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        await alertDialog.present();
      }
    },

    async fetchResource() {
      await this.$store.dispatch("invoice/showResource", {
        resourceId: this.invoice,
      });
    },

    async fetchRazorpay() {
      try {
        await this.$store.dispatch("razorpay/showResource", {
          resourceId: this.invoice,
        });
      } catch (error) {
        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        await alertDialog.present();
      }
    },
  },

  created() {
    this.fetchResource();
  },
};
</script>

<style scoped>
</style>
